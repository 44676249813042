require("@fancyapps/fancybox");
//import Swiper, { Navigation, Pagination } from "swiper";
import Swiper from "swiper/bundle";
import { LadzoMessage as Lm } from "../global/ladzo-message.js";
import { LadzoConsole as Lc } from "../global/ladzo-console.js";

var scrollToElement = require("scroll-to-element");
var m; //odkaz na mapu Seznamu

function zobrazDalsiFotky() {
  $("#dvFotky a#aRozbal").click(function(e) {
    $(this)
      .closest(".flx")
      .find("div")
      .show();
    $(this).remove();
    e.preventDefault();
  });
}

export function carousel() {
  // configure Swiper to use modules
  //Swiper.use([Navigation, Pagination]);
  // init Swiper:
  new Swiper("#carouselDetail", {
    // Enable lazy loading
    lazy: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".dvNext",
      prevEl: ".dvPrev"
    }
  });

  $("a.aCar").click(function(e) {
    var $idd = $(this).data("id");
    $('a.aFancy[data-id="' + $idd + '"]').trigger("click");
    e.preventDefault();
  });
}

export function fotogalerie() {
  zobrazDalsiFotky();
  $("#dvFotky a.aFancy").fancybox({
    autoStart: false
  });
  /*
  $("#dvFotky a.aOrez").fancybox({
    iframe: {
      preload: false
    }
  });
  */
}

function posunNaKontaktniFormular() {
  $("#dvTitle a.prohlidka").click(function(e) {
    // with options
    scrollToElement("#frmKontakt", {
      offset: -140,
      ease: "in-quad", //out-bounce
      duration: 800
    }).on("end", () => {
      //$("#dvScrollMenu").removeClass("show");
      //console.log("Done scrolling!");
    });

    e.preventDefault();
  });
}

function formularDotazNaCenu() {
  $("#dvTitle  a.cena").fancybox({
    autoStart: false,
    src: $("#dvFormCena"),
    type: "html"
  });
}

function zobrazeniVsechParametru() {
  $("#dvParametry a#aParam").click(function(e) {
    $(this)
      .closest(".p")
      .find("div.param")
      .show();
    $(this).remove();
    e.preventDefault();
  });
}

function vlozMapu() {
  var div = $("#maps");
  if (!div.length) return false;

  var stred = SMap.Coords.fromWGS84(div.data("lng"), div.data("lat"));
  m = new SMap(JAK.gel("maps"), stred, div.data("zoom"));
  m.addDefaultLayer(SMap.DEF_BASE).enable();
  m.addDefaultControls();

  var mouse = new SMap.Control.Mouse(SMap.MOUSE_PAN /* |  SMap.MOUSE_ZOOM */); // Ovládání myší
  m.addControl(mouse);

  var layer = new SMap.Layer.Marker(); /* Vrstva pro značku */
  m.addLayer(layer).enable();

  var options = {};
  var marker = new SMap.Marker(stred, "myMarker", options);
  layer.addMarker(marker);

  //m.getSignals().addListener(window, "control-mouse-zoom", click);

  $("a#aSetZoom").click(function(e) {
    Lc.log("mapa", m);
    Lc.log("mapa", m._zoom);

    let idReality = $("body").data("nemovitost");
    $.post("/admin/ajax/ajax-set-mapa-zoom.php", {
      idReality: idReality,
      zoom: m._zoom
    }).done(function(data) {
      if (data.stav == "ok") {
        Lm.ok();
      }
    });

    e.preventDefault();
  });
}

export function all() {
  posunNaKontaktniFormular();
  formularDotazNaCenu();
  zobrazeniVsechParametru();
  vlozMapu();
}
