import { LadzoConsole as Lc } from "./ladzo-console.js";

class ClsLadzoMessage{
    static OK = 33;
    static ERROR = 99;
    ok(hlaska='uloženo'){
        this.message(hlaska,LadzoMessage.OK);
    }
    error(hlaska='error'){
        this.message(hlaska,LadzoMessage.ERROR); 

    }
    message(hlaska,typ){
        Lc.f('ClsLadzoMessage.message');
        let div =$('.saveXhr');
        div.find('span').text(hlaska);
        div.removeClass('ok').removeClass('error');

        div.addClass(typ== LadzoMessage.OK ? 'ok' : 'error');
        div.show().delay(5000).fadeOut();

    }




}


var LadzoMessage =  new ClsLadzoMessage();

export { LadzoMessage };