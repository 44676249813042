//import "./styl.less";
//import * as produkty from "./js/incl/produkty.js";
import { LadzoConsole as Lc } from "./js/global/ladzo-console.js";
import * as detail from "./js/page/detail.js";
import * as uvod from "./js/page/uvod.js";
import * as kategorie from "./js/page/kategorie.js";
import * as produkty from "./js/incl/produkty.js";
import * as nav from "./js/incl/navigace.js";
var CookiesEuBanner = require("cookies-eu-banner/dist/cookies-eu-banner.js");

//https://www.npmjs.com/package/sticky-js
//var Sticky = require("sticky-js");

var Url = new URL(window.location.href);

if (process.env.NODE_ENV == "development" || Url.searchParams.has("debug")) {
  Lc.zobraz({ version: "1.0.1", appName: "Rkczechia" });
}

$(document).ready(function() {
  /*
  new Sticky("header", {
    stickyClass: "sticked"
  });
  */
  nav.zobrazeniNavigace();
  produkty.upravProdukty();

  new CookiesEuBanner(function() {
    // Your code to launch when user accept cookies
  }, true);

  $(window).on("scroll", function() {
    var winTop = $(window).scrollTop();
    if (winTop >= 1) {
      $("body").addClass("bdFixed");
      //$("header,#dvlogin").addClass("fixed");
    } else if (winTop <= 0) {
      $("body").removeClass("bdFixed");
      //$("header,#dvlogin").removeClass("fixed");
    }
  });

  if ($("body").attr("id") == "bdRealita") {
    detail.carousel();
    detail.fotogalerie();
    detail.all();
  } else if ($("body").attr("id") == "bdUvod") {
    uvod.carousel();
  } else if ($("body").hasClass("bdKat")) {
    kategorie.all();
  }

  /*
  let kontrolovatSklady = window.kontrolaSkladu || false;

  if (kontrolovatSklady) {
    produkty.ziskejDataSkladu();
    document.addEventListener("ShoptetDOMPageContentLoaded", function () {
      produkty.ziskejDataSkladu();
    });
    document.addEventListener("ShoptetDOMPageMoreProductsLoaded", function () {
      produkty.ziskejDataSkladu();
    });

    if ($("body").hasClass("type-product")) {
      produkty.ziskejDataSkladuJednohoProduktu();
    }
  }
  */
  /*
 if ($("body").attr("id") == "bdAdmReality") {
  reality.zobrazReality();
}
*/
});

//nacteni vsech produktu z cache

//ziskani vsech produktu na strance a kontrola zda jsou v cachi platne data

//ulozeni novych produktu do cache - tedy ulozeni celeho objektu cache
