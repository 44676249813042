import { LadzoConsole as Lc } from "../global/ladzo-console.js";

var select;
var divOblast;
function nactioOblasti(val = "") {
  if (!val) {
    divOblast.hide();
    return false;
  }

  var searchParams = new URLSearchParams(window.location.search);

  var hodnota;
  var arrOblasti = [];
  // Display the keys
  for (var key of searchParams.keys()) {
    hodnota = searchParams.get(key);
    if (key.substring(0, 6) == "oblast") {
      arrOblasti.push(hodnota);
    }
  }

  divOblast.load(
    "/admin/ajax/ajax-oblasti-pro-kraj.php?krajId=" +
      val +
      "&oblasti=" +
      arrOblasti.join() +
      " #dvOblast > *",
    function(data) {
      divOblast.show();
      Lc.ajx("nactioOblasti", data);
    }
  );
}

export function all() {
  select = $('.dvLokalita select[name="region"]');
  divOblast = $("#dvOblast");
  $("#aPodrobne").click(function(e) {
    let form = $("#frmVyhl");
    let a = $(this);

    if (form.hasClass("exp")) {
      form.removeClass("exp");
      a.text("PODROBNĚJŠÍ HLEDÁNÍ");
    } else {
      form.addClass("exp");
      a.text("MÉNĚ PODROBNÉ");
    }
    e.preventDefault();
  });

  nactioOblasti(select.val());
  select.change(function() {
    Lc.f("select.change", $(this).val());
    divOblast.find("input.check").prop("checked", false);
    nactioOblasti($(this).val());
  });
}
