import { LadzoConsole as Lc } from "../global/ladzo-console.js";
//var ls = require("local-storage");
var Cookies = require("js-cookie/src/js.cookie.js");

//import { CookieStorage } from "cookie-storage";

const PRIDAT_DO_OBLIBENYCH = "Přidat do oblíbených";
const ODEBRAT_Z_OBLIBENYCH = "Odebrat z oblíbených";

var cookieName = "ladzo_oblibene";
/*
var storage;
function defineStorage() {
  storage = new CookieStorage({
    path: "/",
    domain: window.location.host,
    expires: new Date(),
    secure: true,
    sameSite: "Strict" // Can be 'Strict' or 'Lax'.
  });
}
*/

function nactiOblibene() {
  //defineStorage();
  //let objOblibene = ls.get(cookieName) || {};
  //let objOblibene = storage.getItem(cookieName) || {};
  let objOblibene = Cookies.getJSON(cookieName) || {};

  for (var idProduktu in objOblibene) {
    $('.dvReality a.fav[data-produkt="' + idProduktu + '"]')
      .addClass("sel")
      .attr("title", ODEBRAT_Z_OBLIBENYCH);
  } //for
}
function pridaniDoOblibenych() {
  //defineStorage();
  $(".dvReality a.fav").click(function(e) {
    let a = $(this);
    let idProduktu = a.data("produkt");
    //let arrOblibene = ls.get(cookieName) || {};
    //let arrOblibene = storage.getItem(cookieName) || {};
    let arrOblibene = Cookies.getJSON(cookieName) || {};
    if (a.hasClass("sel")) {
      delete arrOblibene[idProduktu];
      a.removeClass("sel");
      a.attr("title", PRIDAT_DO_OBLIBENYCH);

      if ($("body").hasClass("bdOblibene")) {
        a.closest(".item").remove();
        Lc.log("pocet", arrOblibene);
        if (!Object.keys(arrOblibene).length) {
          location.reload();
        }
      }
    } else {
      arrOblibene[idProduktu] = "";
      a.addClass("sel");
      a.attr("title", ODEBRAT_Z_OBLIBENYCH);
    }
    //ls.set(cookieName, arrOblibene);
    Cookies.set(cookieName, arrOblibene, { expires: 365 });
    //storage.setItem(cookieName, arrOblibene);
    e.preventDefault();
  });
}
export function upravProdukty() {
  Lc.f("uprav produkty");
  nactiOblibene();
  pridaniDoOblibenych();
}
