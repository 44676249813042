import { LadzoConsole as Lc } from "../global/ladzo-console.js";
var scrollToElement = require("scroll-to-element");

export function zobrazeniNavigace() {
  Lc.f("zobrazeniNavigace");

  let a = $("#dvBMenu a.menu");
  let body = $("body");
  $(a).click(function(e) {
    if (body.hasClass("bdShowMenu")) {
      body.removeClass("bdShowMenu");
    } else {
      body.addClass("bdShowMenu");
    }

    e.preventDefault();
  });

  $("#dvMenu a").click(function(e) {
    body.removeClass("bdShowMenu");
    if ($(this).attr("href") == "/kontakty/") {
      scrollToElement("#dvPata .dvKont", {
        offset: -140,
        ease: "in-quad", //out-bounce
        duration: 800
      }).on("end", () => {
        //$("#dvScrollMenu").removeClass("show");
        //console.log("Done scrolling!");
      });
      e.preventDefault();
    }
    if ($(this).hasClass("close")) {
      e.preventDefault();
    }
  });
}
