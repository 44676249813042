import Swiper from "swiper/bundle";
import { LadzoConsole as Lc } from "../global/ladzo-console.js";

export function carousel() {
  // configure Swiper to use modules
  //Swiper.use([Navigation, Pagination]);
  // init Swiper:
  var carouselDiv = $("#dvUvCarousel");
  var Sw = new Swiper("#dvUvCarousel", {
    // Enable lazy loading
    // lazy: true,

    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".dvNext",
      prevEl: ".dvPrev"
    }
  });

  $("#dvMenuCarousel a").click(function(e) {
    var tento = $(this);
    var index = tento.data("index");
    Lc.log("index", tento.data("index"));

    /*var callback = function($pom) {
      Lc.f("callback", $pom);
    };
    */
    Sw.slideTo(index, 400, true);

    e.preventDefault();
  });

  Sw.on("slideChange", function(p) {
    Lc.log("slide changed");
    Lc.log("p", p);
    Lc.log("index2", Sw.activeIndex);

    $("#dvMenuCarousel a").removeClass("active");
    $('#dvMenuCarousel a[data-index="' + Sw.activeIndex + '"]').addClass(
      "active"
    );
  });

  carouselDiv.mouseenter(function() {
    Sw.autoplay.stop();
  });

  carouselDiv.mouseleave(function() {
    Sw.autoplay.start();
  });
}
